import React from "react"
import useTranslations from "../../utils/useTranslations"
import ComparisonPanels from "../../components/comparisonPanels"
import FeaturesList from "../../components/featuresList"
import { KasperskyHOC } from "../../components/kasperskyHook"

import heroImgWebp from "../../images/products/heroes/ui-classic-pro.webp"
import heroImg from "../../images/products/heroes/ui-classic-pro.png"
import heroImgWebp2x from "../../images/products/heroes/ui-classic-pro@2x.webp"
import heroImg2x from "../../images/products/heroes/ui-classic-pro@2x.png"


import Button from "../../components/button"
import Logo from "../../components/logo"
import backgrounds from "../../components/backgrounds"
import TipsFaq from "../../components/tipsFaq"

const heroImgAlt = "Buy µTorrent Classic Pro"

class BittorrentClassicPremium extends React.Component {

  render(){

    const { text, pageName } = this.props.pageContext
    const t = useTranslations(text)
    const { unlocalizedPath }  = this.props.pageContext
    const {isTR, isKO, kasperskyBanner} = this.props.myHookValue;

    return (
      <>
        <div className="top-section-container pt-md-2">

          <div className="container text-dark py-2">

            <div className="d-flex text-center mt-3 my-sm-4 justify-content-center">

              <div className="col-lg-6 mt-lg-4 order-lg-1 px-lg-0">
                <Logo secondaryColor="black" tag="p" productName="Classic Pro" className="mb-4" fsMax={27} fsCoefficient={24} fixedParentDistance={3}/>
                <img src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none mb-2 ml-auto"></img>
                <h1 className="text-size-24 text-spacing-1 font-weight-normal">{t(`Go Pro to block bad torrents that have viruses.`)}</h1>
                <h2 className="text-size-16 text-spacing-1 font-weight-normal my-3">{t(`Advanced security, no ads, HD media player, support and more`)}</h2>
                <Button  href={`https://store.utorrent.com/849/purl-utproweb?coupon-code-to-add=e76a5a26-d373-45a5-9922-69ca81807091`} id="products-win-classic-premium-hero-cta" className="btn-primary mt-3 mt-md-4 mb-4 button-filled text-spacing-1 px-3 text-unset">{t(`Get Pro for $19.95/year`)}</Button>
              </div>
              
            </div>
            <div className="col-md-12 d-none d-md-block mb-5 order-md-2">
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block"></img>*/}

                <picture className="d-flex justify-content-center">
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block shadow-lg rounded-xl"></img>
                </picture>

              </div>
          </div>
        </div>

        <div className="container-fluid overflow-hidden position-relative">
          <div className="container z-2">
              <div className="container d-flex justify-content-center align-items-center py-2 px-0 my-4 mt-sm-5">
                <div className="col-12 order-md-1 d-flex flex-column justify-content-center align-items-center">               
                  <h2 className="font-weight-bold text-md-left feature-list-title">{t(`Features`)}</h2>
                  <p className="font-weight-normal mb-3 mt-3 text-center text-md-left feature-list-title-description">{t(`µTorrent Classic Pro Features`)}</p>              
                </div>
              </div>
            <div className = "index-features-list m-auto pb-5">
              <FeaturesList text={text} pageName={pageName}/>
            </div>
          </div>
        </div>

        <div className="container-fluid position-relative overflow-hidden panel-bg">
          <div className="container my-5 text-center z-2">
            <p className="text-center font-weight-bold feature-list-title mb-3">{t(`Compare`)}</p>
            <h2 className="text-center mb-5 text-size-24 font-weight-normal">
                {t(`Compare µTorrent Classic Versions`)}
            </h2>
          </div>

          {
            kasperskyBanner
          }
          <ComparisonPanels pageContext={{ "text": text, "pageName":pageName, "isMac":true, "isKasperskyTR": isTR, "isKasperskyKO": isKO}} />
        </div>

        <TipsFaq type="tips" header={t(`Tips`)} subheader={t(`µTorrent Classic Pro Product Tip`)}>
          <div className="col-12 px-0">
            <p className="product-faq-question">{t(`What makes µTorrent Classic Pro better than the free version?`)}</p>
            <p className="product-faq-answer">{t(`The free version µTorrent Classic is already feature rich, making it great for automating torrent downloads, managing your bandwidth and data usage, customizing the interface and more. With µTorrent Pro, you go ads-free and get an important layer of security to ensure your torrent PC downloads are safe. Each torrent download is scanned for malware and viruses with a leading anti-virus solution, ensuring that your files are delivered securely. The Pro version also includes a file converter and an HD media player to help you quickly playback all your audio and video files.`)}</p>
            <p className="product-faq-answer">{t(`If you want a little more, we offer µTorrent Classic Pro+VPN that includes one-year of CyberGhost VPN. With the subscription supporting 5 simultaneous device connections, you can ensure online privacy on your desktop computer, mobile phone, tablets, and more.`)}</p>
          </div>
        </TipsFaq>


      </>
    )

  }
}

// export default BittorrentClassicPremium
export default KasperskyHOC(BittorrentClassicPremium)
